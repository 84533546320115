@import url('https://fonts.googleapis.com/css2?family=Alef:wght@400;700&display=swap');

body {
  margin:0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Alef', sans-serif;
}
.App {
  padding:30px 50px 50px;
  text-align:center;
  font-family:Helvetica;
}
.App p {
  color:#379683;
  font-weight:700;
}
.logo {
  padding:20px;
}
.logo--white {
  background: #272729;
}
.colors {
  display:flex;
  flex-flow:row wrap;
  height:200px;
  width:400px;
  margin:0 auto;
  padding:50px;
}
.colors div {
  flex:0 0 25%;
}
.colors .color1 {
  background:#05386b;
}
.colors .color2 {
  background:#85cdcb;
}
.colors .color3 {
  background:#8ee4af;
}
.colors .color4 {
  background:#edf5e1;
}
.colors .color5 {
  background:#41b3a3;
}
.colors .color6 {
  background:#5cdb95;
}
.colors .color7 {
  background:#379683;
}
.colors .color8 {
  background:#272729;
}